import React, { useState } from 'react'
import { Card, Row, Col, Input } from 'reactstrap'
import PCStarIcon from '../../../../../../../../designUtils/Storeoverview/PartnerLogs/PCStarIcon.svg'
import PCArchiveIcon from '../../../../../../../../designUtils/Storeoverview/PartnerLogs/PCArchiveIcon.svg'
import PCBackArrowIcon from '../../../../../../../../designUtils/Storeoverview/PartnerLogs/PCBackArrowIcon.svg'

function AllPartnerUpdateLog() {
    const [ticketDetails, setTicketDetails] = useState(false)
    const handleTicketDetails = () => {
        setTicketDetails(true)
    }
    return (
        <>
            {!ticketDetails ?
                <Card className='curve-popup p-3'>

                    <Row className="row-hover" onClick={() => handleTicketDetails()}>
                        <Col md={1}>
                            <h6><img src={PCStarIcon} /></h6>
                        </Col>
                        <Col md={2}>
                            <h6><b>Dec 26</b></h6>
                        </Col>
                        <Col md={3}>
                            <h6><b>Oprational Managements</b></h6>
                            <h6><b>Latest Comments........</b></h6>
                        </Col>
                        <Col md={2}>
                            <h6><b>Ticket #1319</b></h6>
                        </Col>
                        <Col md={1}> <h6 className='img-archive-icon'><img src={PCArchiveIcon} /></h6> </Col>
                        <Col md={3} className='d-flex'>
                            <h6><b>issue Description.............</b></h6>

                        </Col>
                    </Row>
                    <Row className='mt-1'>
                        <Col md={1}>
                            <h6><img src={PCStarIcon} /></h6>
                        </Col>
                        <Col md={2}>
                            <h6>Dec 26</h6>
                        </Col>
                        <Col md={3}>
                            <h6>Oprational Managements</h6>
                            <h6>Latest Comments........</h6>
                        </Col>
                        <Col md={3}>
                            <h6>Ticket #1320</h6>
                        </Col>
                        <Col md={3}>
                            <h6>issue Description.............</h6>
                        </Col>
                    </Row>
                    <Row className='mt-1'>
                        <Col md={1}>
                            <h6><img src={PCStarIcon} /></h6>
                        </Col>
                        <Col md={2}>
                            <h6><b>Dec 26</b></h6>
                        </Col>
                        <Col md={3}>
                            <h6><b>Oprational Managements</b></h6>
                            <h6><b>Latest Comments........</b></h6>
                        </Col>
                        <Col md={3}>
                            <h6><b>Ticket #1321</b></h6>
                        </Col>
                        <Col md={3}>
                            <h6><b>issue Description.............</b></h6>
                        </Col>
                    </Row>
                    <Row className='mt-1'>
                        <Col md={1}>
                            <h6><img src={PCStarIcon} /></h6>
                        </Col>
                        <Col md={2}>
                            <h6>Dec 26</h6>
                        </Col>
                        <Col md={3}>
                            <h6>Oprational Managements</h6>
                            <h6>Latest Comments........</h6>
                        </Col>
                        <Col md={3}>
                            <h6>Ticket #1320</h6>
                        </Col>
                        <Col md={3}>
                            <h6>issue Description.............</h6>
                        </Col>
                    </Row>
                </Card>

                :
                <Card className='curve-popup p-3'>
                    <Row>
                        <Col md={12} className='mt-1'>
                            <Input
                                type='text'
                                className='w-25'
                                placeholder='Serch in chat'
                            />
                        </Col>
                        <Col md={12} className='d-flex mt-2 justify-containt-center'>
                            <p> <img className='' onClick={()=>setTicketDetails(false)}src={PCBackArrowIcon} /> </p>
                            <p className='ml-4'>  <img  src={PCStarIcon} /></p>
                            <p className='ml-4 mt-1'>Action Required</p>
                            <p className='ml-4 mt-1'> <img src={PCArchiveIcon} /></p>
                        </Col>
                        <Col md={12} className='d-flex'>
                            <h6>issue Description</h6>
                        </Col>
                        <Col md={12} className='d-flex'>
                            <h6><b>Ticket #1319</b></h6>
                            <h6 className='ml-2'><b>Type : Oprational Managements</b></h6>
                            <h6 className='ml-2'><b>Thursday,December 26, 2024</b></h6>
                        </Col>


                    </Row>
                    <Row className='d-flex align-items-center'>
                        <Col md={1}>
                            <h5 className='display-name'>SW</h5>
                        </Col>
                        <Col md={11}>
                            <Card className='curve-popup-partner-log-chat p-3 '>
                                <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                        <Col md={11}>
                            <Card className='curve-popup p-3 mt-2'>
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                            </Card>
                        </Col>
                        <Col md={1}>
                            <h5 className='display-name'>MN</h5>
                        </Col>
                    </Row>
                </Card>}
        </>


    )
}

export default AllPartnerUpdateLog
