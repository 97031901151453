import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import AllPartnerUpdateLog from './Components/AllPartnerUpdateLog'
import NotificationLogs from './Components/NotificationLogs'
import ReportLogs from './Components/ReportLogs'
import OtherLogs from './Components/OtherLogs'
import ActionRequiredLogs from './Components/ActionRequiredLogs'
import PCStarIcon from '../../../../../../../designUtils/Storeoverview/PartnerLogs/PCStarIcon.svg'
function PartnerUpdateLogMainFile() {
  const [pageFlags, setPageFlags] = useState('1')
  const handlePartnerUpdateLog = (flag) => {
    setPageFlags(flag)
  }
  return (
    <Container className='mt-3'>
      <Row>
        <Col md={4}><h5><b>Hello,Partner #id</b></h5>
          <p><b>Thursday,December 26, 2024</b></p></Col>
      </Row>
      <Row>
        <Col md={12} className='d-flex'>
          <h6><img src={PCStarIcon}/></h6>
          <h5 onClick={() => handlePartnerUpdateLog('1')} className='ml-3'>All</h5>
          <h5 onClick={() => handlePartnerUpdateLog('2')} className='ml-3'>Action Required</h5>
          <h5 onClick={() => handlePartnerUpdateLog('3')} className='ml-3'>Notification</h5>
          <h5 onClick={() => handlePartnerUpdateLog('4')} className='ml-3'>Report</h5>
          <h5 onClick={() => handlePartnerUpdateLog('5')} className='ml-3'>Others</h5>
        </Col>
      </Row>
      {pageFlags === '1' && <AllPartnerUpdateLog />}
      {pageFlags === '2' && <ActionRequiredLogs />}
      {pageFlags === '3' && <NotificationLogs />}
      {pageFlags === '4' && <ReportLogs />}
      {pageFlags === '5' && <OtherLogs />}
    </Container>
  )
}

export default PartnerUpdateLogMainFile
