import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { connect } from "react-redux";
import ReactQuill from 'react-quill';
import { Editor, SetInnerHtmlWithClassReplacement } from '../../../../../../../../Utils/Constants';
import { faEye, faDownload,faPaperclip, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactTable from 'react-table';
import GetTaskHistoryService from '../../../../../../../../Service/OPSServices/SWInternalServices/GetTaskHistoryService';
import AddHistoryService from '../../../../../../../../Service/OPSServices/SWInternalServices/AddHistoryService';
import CustomModal from '../../../../../../../Components/Modals/CustomModal';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';

function ViewTaskHistory(props) {
  const [searchInput, setSearchInput] = useState("");
  const [text, setText] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [historyList, setHistoryList] = useState([]);
  const [taskStatus, setTaskStatus] = useState("");
  const [member, setMember] = useState("");
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [isGridCommentsOpen, setIsGridCommentsOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [saveLoading, setSaveLoading] = useState(false)
  const [completing, setCompleting] = useState(false)
  const [commentsText, setCommentsText] = useState()
  const [popupHistory, setPopupHistory] = useState('')
  const [loadScreen, setLoadScreen] = useState(false)
  useEffect(() => {
    setLoadScreen(true)
    console.log(props.viewTaskData);
    const data = {
      taskId: props.viewTaskData.SWTeamTaskId
    }
    GetTaskHistoryService(props.token, data).then((responce) => {
      if (responce) {
        console.log('responce')
        console.log(responce)
        setHistoryList(responce.historyList)
        setLoadScreen(false)
      }
    })

  }, [])

  const handleChangeStatus = (e) => {
    setTaskStatus(e.target.value)
  }
  const handleAssignedToChange = (e) => {
    setMember(e.target.value)
  }
  const handleQuillChange = (value) => {
    setText(value);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleSubmitHistory = (flag) => {

    const formData = new FormData();

    formData.append("taskId", props.viewTaskData.SWTeamTaskId);
    formData.append("status", taskStatus);
    formData.append("note", text);
    formData.append("flag", 0);
    if (flag == 1) {
      formData.append("isTaskCompleted", 1);
      setCompleting(true)
    }
    if (flag == 2) {
      formData.append("isTaskCompleted", 2);
      setSaveLoading(true)

    }
    formData.append("assignedTo", member ? member : props.viewTaskData.assignedTo ? props.viewTaskData.assignedTo : '');
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("taskAttachmentFile", selectedFiles[i]);
      }
    }
    else {
      formData.append("taskAttachmentFile", []);
    }
    console.log(formData);
    AddHistoryService(props.token, formData).then((res) => {
      if (res) {
        console.log(res)
        setSelectedFiles([])
        setTaskStatus('')
        setText('')
        setMember('')
        // setHistoryList(res.historyList)
        props.handleCloseCreateTaskPopup(res.tasklist)
      }
      setCompleting(false)
      setSaveLoading(false)
    })

  }
  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
  const downloadFile = async (fileUrl) => {
    console.log(fileUrl)
    try {
      // Extract the file name from the URL
      const fileName = fileUrl.taskAttachmentFile.split('/').pop(); // Gets "7_Day_Deals_BrandProfile_Questionnaire_2.pdf"

      // Fetch the file from the URL
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error(`Network response was not ok for ${fileUrl}`);

      // Convert the response to a blob
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary download link
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = fileName; // Use the extracted file name

      // Append the link to the document body, trigger download, and clean up
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl); // Clean up the blob URL to release memory

    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };
  const [isExpanded, setIsExpanded] = useState(false)
  const ReadMore = ({ htmlContent, maxLength, history }) => {

    const toggleReadMore = () => {
      setPopupHistory(history)
      setIsExpanded(!isExpanded);
    };

    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div>
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{ fontSize: '13px' }} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            ....Read More
          </span>
        )}
      </div>
    )
  };

  const show = (path) => {
    // Extract file extension from the file path
    console.log(path)
    const separatedPath = path.split("/");
    const fileName = separatedPath.pop(); // Get the file name with extension
    const fileExtension = fileName.split(".").pop(); // Get the extension

    // Check for file extensions that should be closed quickly after showing the modal
    const quickCloseExtensions = ["xlsx", "csv", "xls", "docx"];

    // If the file is of a type that requires quick close (like .xlsx, .csv, etc.)
    if (quickCloseExtensions.includes(fileExtension)) {
      setFilePath(path);        // Set the file path for displaying in the iframe
      setIsAttachmentOpen(true); // Open the modal

      // Close the modal after 200 milliseconds
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      // For all other file types (e.g., PDFs, images), just show the modal without auto-closing
      setFilePath(path);        // Set the file path for the iframe
      setIsAttachmentOpen(true); // Open the modal
    }
  };
  const replaceCommentsText = SetInnerHtmlWithClassReplacement(commentsText);


  return (
    <Row>
      <Col md={6}>
        <Card className='card-of-problemarea  p-3'>
          <Row>
            <Col md={6}>
              <Label><b>Task Title : </b>{props.viewTaskData.taskTitle}</Label>
            </Col>
            <Col md={6}>
              <Label><b>Completed Date : </b>{props.viewTaskData.completedAt}</Label>
            </Col>

            <Col md={6} className='mt-1 mb-1'>
              <Label><b>Planned Start Date : </b>{props.viewTaskData.plannedStartDate}</Label>
            </Col>
            <Col md={6} className='mt-1 mb-1'>
              <Label><b>Planned End Date : </b>{props.viewTaskData.plannedEndDate}</Label>
            </Col>

            <Col md={6}>
              <Label ><b>Assigned To : </b>
                <select
                  id='assigned To'
                  onChange={(e) => handleAssignedToChange(e)
                  }
                  className='form-control w-100 mt-2'>
                  <option id='' value=''>--Assigned To--</option>
                  {props.teamMemberList && props.teamMemberList.map((member) => (
                    <option
                      value={member.employeeId}
                      selected={member.employeeId == props.viewTaskData.assignedTo}
                    >{member.employeeName}</option>

                  ))}
                </select></Label>

            </Col>
            <Col md={6} >
              <Label ><b>Status : </b>
                <select className='form-control w-100 mt-2'
                  id='newTask'
                  name='newTask'
                  // value={taskStatus}
                  onChange={handleChangeStatus}>
                  <option id='' value=''>--Select Status--</option>
                  {
                    props.statusList && props.statusList.map((status) => (
                      <option value={status.status} selected={status.status === props.viewTaskData.status}>{status.status}</option>
                    ))
                  }
                </select>
              </Label>
            </Col>
            <Col className='mt-2' md={6}>

              <p for="Attachment">
                <b>Helpful Screenshots Or Images : </b>
              </p>
              <label
                htmlFor="fileInput"
                className="custom-file-input-label text-center"
              >
                <i className="fas fa-upload"></i>
                <br /> Choose File

                <input
                  type="file"
                  id="fileInput"
                  name="ticketAttachment"
                  className="custom-file-input"
                  onChange={handleFileChange}
                />
              </label>
            </Col>
            <Col md={6}>
              <span style={{ fontSize: "13px" }}><b>Attached File List :</b></span>
              <ul style={{ color: "green", fontSize: "13px" }}>
                {selectedFiles
                  ? selectedFiles.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <button
                        className="btn"
                        onClick={() => handleRemoveFile(file)}
                      >
                        <FontAwesomeIcon
                          color="red"
                          size="15px"
                          icon={faWindowClose}
                        />
                      </button>
                    </li>
                  ))
                  : ""}
              </ul>

            </Col>

            <Col md={12} className='mt-2'>
              {/* <div className='mendetory-star'>
            <span style={{ color: 'red' }}>
              <b> * </b>
            </span>
          </div> */}
              <Label><b>Add Note For Tasks <span style={{ color: 'red' }}> *</span></b></Label>
              {/* <div className='star-quell'> */}
              <ReactQuill
                className=""
                theme={'snow'}
                modules={Editor.modules}
                formats={Editor.formats}
                value={text}
                onChange={handleQuillChange}
                bounds={'.quill-prod'}
                placeholder='Note' />
              {/* </div> */}
            </Col>
            <Col md={12} className='mt-2 d-flex justify-content-center'>
              <Button className='btn-background-color '
                disabled={!(text) || (completing || saveLoading)}
                onClick={() => handleSubmitHistory(1)}> {completing ? 'Saving..' : 'Complete'} </Button>
              <Button className='btn-background-color ml-3'
                disabled={!(text) || (saveLoading || completing)}
                onClick={() => handleSubmitHistory(2)}> {saveLoading ? 'Saving..' : 'Save'} </Button>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col md={6}>
        <LoadingOverlay
          active={loadScreen}
          styles={{

            overlay: (base) => ({
              ...base,
              background: '#f1f4f6',
              opacity: 1,
              marginTop: '15px'
            }),
            content: (base) => ({
              ...base,
              color: '#000',
            }),
          }}
          spinner={<Loader active type="ball-pulse" />}
          text="Loading task history Comments..."
        >
           <Card className='card-of-problemarea p-2 mb-1 '>
          {historyList && historyList.map((history) => {
            console.log(history)
            const historyNote = SetInnerHtmlWithClassReplacement(history.note)
            return (
             
                <Col md={12} >
                  <Row className='task-history mt-1'>
                    <Col md={6}><h6><b>{history.createdByName}</b></h6></Col>
                    <Col md={6}> <h6><b>{history.createdAt}</b></h6></Col>
                    <Col md={12}>
                      <ReadMore htmlContent={historyNote} maxLength={200} history={history.note} />
                    </Col>
                    {
                      history.files && history.files.length >0?
                      <Col md={12}>
                        {
                          history.files.map((file)=>(
                            <span>
                              {console.log(history.files)}
                              {console.log(file)}
                            <FontAwesomeIcon
                            className="mr-2"
                            icon={faPaperclip}
                            onClick={() => show(file.taskAttachmentFile)}
                        />

                            </span>
                          ))
                        }
                    
                      </Col>:''
                    }
                  
                  </Row>
                </Col>
            
            )
          })}
            </Card>

        </LoadingOverlay>
      </Col>


      <CustomModal
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'taskAttachmentFile': filePath, 'SWTeamTaskAttachmentId': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={'lg'}
        className='other-bgColor'
      >
      <Card  className='card-of-problemarea p-2 '>
          <div>
            <iframe
              src={

                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
      </Card>
      </CustomModal>
      <CustomModal
        header={'Comments'}
        isOpen={isGridCommentsOpen}
        toggle={() => setIsGridCommentsOpen(!isGridCommentsOpen)}
        className='other-bgColor'
        size={'md'}
      >
        <Card className='card-of-problemarea'>
          <Col md={12} className='p-3'>
            <Label>
              <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceCommentsText }} />
            </Label>
          </Col>
        </Card>

      </CustomModal>
      <CustomModal
        header={"Task comment"}
        isOpen={isExpanded}
        toggle={() => setIsExpanded(!isExpanded)}
        size={"xl"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpanded(!isExpanded)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="issue-discription-set" style={{ fontSize: '19px' }} dangerouslySetInnerHTML={{ __html: popupHistory }} />
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(ViewTaskHistory)