import React, { useState } from 'react'
import { Card, Row, Col, Input } from 'reactstrap'

function ReportLogs() {
  const [ticketDetails, setTicketDetails] = useState(false)
  const handleTicketDetails = () => {
      setTicketDetails(true)
  }
  return (
 <div>ReportLogs</div>
  )
}

export default ReportLogs
