import React, { useState } from 'react'
import { Card, Row, Col, Input } from 'reactstrap'

function OtherLogs() {
  const [ticketDetails, setTicketDetails] = useState(false)
  const handleTicketDetails = () => {
      setTicketDetails(true)
  }
  return (
    <div>OtherLogs </div>
  )
}

export default OtherLogs
