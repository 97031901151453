import React, { useState,useEffect } from 'react'
import WorkflowsMainPage from '../Workflows/WorkflowsMainPage'
import OpsDashTicketListPage from '../opsDashTickets/OpsDashTicketListPage'
import { Card, Col, Container, Row } from 'reactstrap'
import SwTeamCreatetask from './Components/SwTeamCreatetask'
import SWTeamTask from './Components/SWTeamTask'
import NewTaskIcon from './../../../../../../designUtils/OpsDashboard/MyTasks/NewTaskIcon.svg';
import MyTasksUpIcon from './../../../../../../designUtils/OpsDashboard/MyTasks/MyTasksUpIcon.svg';
import MyTicketsUpIcon from './../../../../../../designUtils/OpsDashboard/MyTasks/MyTicketsUpIcon.svg';
import MyWFUpIcon from './../../../../../../designUtils/OpsDashboard/MyTasks/MyWFUpIcon.svg';


import MyTasksDropdown from './../../../../../../designUtils/OpsDashboard/MyTasks/MyTasksDropdown.svg';
import MyTicketsDropdown from './../../../../../../designUtils/OpsDashboard/MyTasks/MyTicketsDropdown.svg';
import MyWFDropdown from './../../../../../../designUtils/OpsDashboard/MyTasks/MyWFDropdown.svg';
import { connect } from 'react-redux';
import PieChart from './Components/PieChart'
import { height } from '@fortawesome/free-solid-svg-icons/faWindowClose'
import CustomModal from '../../../../../Components/Modals/CustomModal'
import CreateNewTask from './Components/SubComponentsSwTeamTask/CreateNewTask'
import GetAllTasksServices from '../../../../../../Service/OPSServices/SWInternalServices/GetAllTasksServices'
import GetUserProfileDataService from '../../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/GetUserProfileDataService'
import getAllStatistics from '../../../../../../Service/OPSServices/SWInternalServices/getAllStatistics'

function MyTasksMainPage(props) {
  const [tabs, setTabs] = useState(false)
  const [showTabsUi,setShowTabsUi]= useState(false)
  const [myTaskIcons,setMyTaskIcons]= useState(false)
  const [ticketIcons,setTicketIcons]= useState(false)
  const [workflowsIcons,setWorkflowsIcons]= useState(false)
  const [createNewTaskPopup,setCreateNewTaskPopup]= useState(false)
  const [taskList, setTaskList] = useState([])
  const [teamMemberList, setTeamMemberList] = useState('')
  const [allStats, setallStats] = useState()
  const [cardTitle, setCardTitle] = useState();

  const [loggedUserId , setloggedUserId] = useState()
  const [taskCount , settaskCount] = useState(0)
  const [worflowCount,setworflowCount]= useState(0)
  const [ticketCount,setticketCount]= useState(0)

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
  });
  const [statusNames, setstatusName] = useState([])
  const [statusColor, setstatusColor] = useState([])
  const [statusCount, setstatusCount] = useState([])
  useEffect(() => {
    getAllStatistics(props.token).then((res) => {
      if (res) {
        console.log(res);
        setallStats(res)
        settaskCount(res.taskStatistics[0].count)
        setworflowCount(res.workflowStatistics[0].count)
        setticketCount(res.ticketStatistics[0].count)
        setstatusName(res.taskStatistics.map((item) => item.status))
        setstatusColor(res.taskStatistics.map((item) => item.color))
        setstatusCount(res.taskStatistics.map((item) => item.count))
      }
    })
    GetAllTasksServices(props.token).then((responce) => {
      if (responce) {
        console.log('responce')
        console.log(responce)
        setloggedUserId(responce.loggedUserId)
        setTaskList(responce.tasklist)
        setTeamMemberList(responce.teamMembers)

        // setTaskStatus(responce.statusList)
        // const statusName = responce.statusList.map(item => item.status);
        // setstatusName(statusName)
        // const tasks = responce.tasklist.filter((task) => task.status != 'Completed' && task.assignedTo == responce.loggedUserId);
        // settaskCount(tasks.length)
      }
    })
    GetUserProfileDataService(props.token).then((response) => {
      if (response) {
        console.log(response);
        setUserData({
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          phone: response.phone,
          companyName: response.companyName,
        })
      }
    })
    handleTimeDate()

  }, [])
  const setStatisticsByTab = (tab) =>{

  }
  const handleChangeTabs = (value) => {
    setStatisticsByTab(value)
    if (value === 1) {
      setCardTitle("Workflows");
      setstatusName(allStats.workflowStatistics.map((item)=>item.status))
      setstatusColor(allStats.workflowStatistics.map((item)=>item.color))
      setstatusCount(allStats.workflowStatistics.map((item)=>item.count))
      setWorkflowsIcons(!workflowsIcons)
      setTicketIcons(false)
      setMyTaskIcons(false)
    }
    if (value === 2) {
      setCardTitle("Tickets");
      setstatusName(allStats.ticketStatistics.map((item) => item.status))
      setstatusColor(allStats.ticketStatistics.map((item) => item.color))
      setstatusCount(allStats.ticketStatistics.map((item) => item.count))
      setTicketIcons(!ticketIcons)
      setMyTaskIcons(false)
      setWorkflowsIcons(false)
    }
    if (value === 3) {
      setCardTitle("SW Team Tasks");
      setstatusName(allStats.taskStatistics.map((item) => item.status))
      setstatusColor(allStats.taskStatistics.map((item) => item.color))
      setstatusCount(allStats.taskStatistics.map((item) => item.count))
      setMyTaskIcons(!myTaskIcons)
      setWorkflowsIcons(false)
      setTicketIcons(false)
    }
    // setMyTaskIcons(value, !myTaskIcons)
    // setShowTabsUi(!showTabsUi)
    setTabs(value)
  }
  const handleCreateTaskPopup = () => {

    setCreateNewTaskPopup(true)
  }
  const handleCloseCreateTaskPopup = (task) => {
    setCreateNewTaskPopup(false)
    setTaskList(task)
  }
  const [currentTime, setCurrentTime] = useState(new Date());
  const handleTimeDate = () => {
    setCurrentTime(new Date());

  }

  const formattedDate = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <Col>
      <Row>
        <Col md={9}>
          <Row>
            <Col md={12}>
              <h5>
                <b>Hello, {userData.firstName}</b>
              </h5>
              <h6> <b>{`${formattedDate}, ${formattedTime}`}</b></h6>
              <div className='d-flex mt-4 mb-3'>
                <div className='d-flex justify-content-center message-color '>
                  <h6 className='font-color-heading  mr-3 mt-2'><b>Messages</b> </h6>
                  <h6 className='message-count'><b>5</b></h6>
                </div>

                <div className='new-task-color' onClick={handleCreateTaskPopup}>
                  <h6 className='font-color-heading  mt-2'><b>New Task </b> <img className='ml-3 mb-1' src={NewTaskIcon} /></h6>
                </div>

              </div>
            </Col>
            <Col md={4}>
              <Card
                onClick={() => { handleChangeTabs(3) }}
                className={` p-4 card-of-problemarea swteam-tasks mt-3`}>
                <h6
                  style={{ color: '#243837' }}
                >
                  <b>SW Team Tasks</b></h6>
                <div className="d-flex justify-content-between">
                  <h6 style={{ color: '#243837' }}><b>{taskCount}</b></h6>

                  <img
                    src={myTaskIcons ? MyTasksDropdown : MyTasksUpIcon} />
                </div>
              </Card>
            </Col>
            <Col md={4}>
              <Card className={` p-4 card-of-problemarea workflow-tasks mt-3`}
                onClick={() => { handleChangeTabs(1) }} >
                <h6
                  style={{ color: '#243837' }}
                >
                  <b>Workflows</b></h6>
                <div className="d-flex justify-content-between">
                  <h6 style={{ color: '#243837' }}><b>{worflowCount}</b></h6>
                  <img src={workflowsIcons ? MyWFDropdown : MyWFUpIcon} />
                </div>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                onClick={() => { handleChangeTabs(2) }}
                className={` p-4 card-of-problemarea ticket-tasks mt-3`} >
                <h6
                  style={{ color: '#243837' }}
                >
                  <b>Tickets</b></h6>
                <div className="d-flex justify-content-between">
                  <h6 style={{ color: '#243837' }}><b>{ticketCount}</b></h6>

                  <img src={ticketIcons ? MyTicketsDropdown : MyTicketsUpIcon} alt="Dropdown Icon" />
                </div>
              </Card>
            </Col>
          </Row>

        </Col>
        <Col md={3}>
          <Card className='card-of-problemarea p-2' style={{ height: '280px' }}>
            <div className='custom-task-overview'>
              <h5 className='text-center'><b>{cardTitle?cardTitle:"SW Team Tasks"}</b></h5></div>
            {/* <h6 className='text-center'><b>Nov 2024</b></h6>    */}
            <PieChart Status={statusNames} count={statusCount} color={statusColor} />
          </Card>
        </Col>
        <Col md={12}>
          {
            (tabs === 1) ?
              <WorkflowsMainPage isMyworksScreen={true} /> :
              (tabs === 2) ?
                <OpsDashTicketListPage isMyworksScreen={true} /> :
                (tabs === 3) ?
                  <SWTeamTask /> : ''
            // <SwTeamCreatetask/>:''

          }
        </Col>
      </Row>
      <CustomModal
        header={"Task Title"}
        isOpen={createNewTaskPopup}
        toggle={() => setCreateNewTaskPopup(!createNewTaskPopup)}
        size={"lg"}
        className='other-bgColor'
      >
          <Card className='card-of-problemarea'>
          <CreateNewTask
            teamMemberList={teamMemberList}
            taskList={taskList}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          />
        </Card>
      </CustomModal>
    </Col>
  )
}

const mapStateToProps = state => {
  return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
  }
}

export default connect(mapStateToProps)(MyTasksMainPage);
